import { render, staticRenderFns } from "./TestInfoView.vue?vue&type=template&id=28e6cc12"
import script from "./TestInfoView.vue?vue&type=script&setup=true&lang=js"
export * from "./TestInfoView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports