<script setup>
import {ref, onBeforeMount, computed, onUnmounted} from 'vue';
import {sleep} from "@/js/helper"
import {useStore} from '@/store';
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import DynamicTestInfoView from '@/components/dynamic/testInfoView.vue';
import TestInfoReloader from '@/components/dynamic/testInfoReloader.vue';
import StartParametersDialogContainer from "@/components/dynamic/startParametersDialogContainer.vue";
import {useRoute, onBeforeRouteUpdate} from 'vue-router/composables';
import { testInfoAdminInfoChannel } from "@/store/channels/test-info-admin-info"

const isRouteUpdating = ref(false);

onBeforeRouteUpdate(async (_, __, next) => {
  isRouteUpdating.value = true;
  next();
  await sleep(500);
  isRouteUpdating.value = false;
});

const store = useStore();
const route = useRoute();
const newResult = computed(() => store.getters.result);

const updateStoreTable = (result) => {
  document.title = result.title;
  store.commit('tableChanged', result.table);
};

const getTestInfoParams = () => ({ n_id: route.params.id, n_id_key: route.params.idKey });

const fetchTestInfoData = async () => {
  await store.dispatch("fetchTestInfoData", getTestInfoParams());
};

onBeforeMount(async () => {
  await fetchTestInfoData();

  testInfoAdminInfoChannel.addCallback(fetchTestInfoData);
});

onUnmounted(() => {
  //clean up
  store.commit("tableChanged", {});
  store.commit("resultChanged", null);
  testInfoAdminInfoChannel.removeCallback(fetchTestInfoData);
});
</script>

<template>
    <PhpServiceView
        :url="`?f=testing&f2=testInfo&function=getInfo&n_id=${$route.params.id}&n_id_key=${$route.params.idKey}`"
        @result="updateStoreTable"
        #default="{result}"
        :is-loader-shown="isRouteUpdating"
    >
      <DynamicTestInfoView :result="newResult ? newResult : result"/>

      <TestInfoReloader
          v-if="(newResult ? newResult : result).content.includes('testInfoReloader')"
          :result="newResult ? newResult : result"
      />

      <StartParametersDialogContainer
          v-if="result.content.includes('startParametersDialogContainer')"
          :result="result"
      />
    </PhpServiceView>
</template>

